import * as React from "react"
import ecosystemImage from "../images/ecosystem.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const blog5 = () => (
  <Layout>
    <SEO title="Ecosystem the future!" />
    <div class="container col-lg-6">
      <article class="blog-post mt-3">
        <h2 class="blog-post-title">Ecosystem The Future!</h2>
        <p class="blog-post-meta">March 05 2021 by Paul</p>
        <img src={ecosystemImage} class="card-img-top mb-3" alt="..." />
        <p>
          Our Global Mobility world is complex with so many moving parts in a
          mobility program. This is why ‘One stop shop’ relocation companies
          have evolved to support such service needs from destination services,
          household goods moving, expense management through to immigration and
          compensation services. These relocation management providers
          identified the needs of multinational companies and provided the
          benefits of only having to contract with one provider across a wide
          spectrum of services.
        </p>
        <p>
          A mobility Ecosystem concept is when two or more relocation providers
          are used for the above- mentioned services. Looking at a mobility
          program from a high level most mobility programs already use multiple
          providers typically for tax, immigration, and relocation services so
          the concept of an ecosystem has been around for many years. Here we
          are focusing on the spectrum of services provided by the ‘One stop
          shop’ relocation management providers and if that model works for your
          program.
        </p>
        <p>
          Can one relocation provider truly deliver the best in all aspects of
          service quality and cost for your program across a wide range of
          services? But you may have no choice as you may only be able to
          contract with one relocation provider, or the convenience of such a
          provider is what you need.
        </p>
        <p>
          But if this isn’t the case, is the ideal solution to your mobility
          challenges based upon working with the best providers in the service
          areas that you need to deliver your mobility program? Imagine a world
          where you could pick the best relocation providers in the key service
          areas you need for example immigration, destination service or
          household goods moving to build your ecosystem!
        </p>
        <p>What is stopping you?</p>
        <p>
          The world is changing, I predict a trend of 2021 will be an increase
          in relocation providers being open to working with other providers in
          a client’s mobility Ecosystem. Market forces driven by COVID may also
          drive the Ecosystem as certain relocation providers focus on core
          service areas where they deliver value and make money. Others will see
          opportunities to move into new service areas which will benefit the
          market. Then we have the relatively new entrants to the provider
          market with their smart technology enabled services focusing on
          certain levels of the market and they seem open to working in an
          Ecosystem.
        </p>
        <p>
          Technology advances increase the ability and ease of data sharing, but
          will providers open their systems to share data and work more openly
          for the benefit of their mutual clients? All these elements will
          potentially push the value of the ecosystem and make it attractive for
          certain mobility programs.
        </p>
        <p>
          The development of the Ecosystem may see a slow start and will depend
          upon the appetite of Global Mobility stakeholders to build an
          ecosystem of providers that delivers the best value for their program.
        </p>
        <p>
          A mobility Ecosystem will not work for every program so you must
          decide if the benefits of an Ecosystem will be worth the investment of
          time. From my experience in such evaluations, the following are a few
          simple questions to start with.
        </p>
        <ol type="1">
          <li class="mb-2">
            Does using one relocation provider to deliver multiple service the
            achieve value you need?
          </li>
          <li class="mb-2">
            Is it mandated possibly by finance or for contract areas such as
            data privacy that you can only have one relocation provider to
            contract with?
          </li>
          <li class="mb-2">
            Do you need an improved, more agile service delivery model, reduced
            costs, and a better employee experience?
          </li>
          <li class="mb-2">
            Do you have the time to contract and manage multiple providers?
          </li>
        </ol>
        <p>
          Discussions with Global Mobility professionals on what an ideal
          Ecosystem would look like for their program have been fascinating.
          Just let me know if you would like me to share my experience to help
          you define what your ideal Ecosystem would look like and discuss how
          it can be achieved.
        </p>
        <p>
          Written by Paul Barnes, Director, Inspire Global Mobility Consulting.
        </p>
        <p>
          Email: <strong>paul@igmobility.com</strong> Mobile: +44 (0) 7534 565
          888
        </p>
      </article>
    </div>
  </Layout>
)

export default blog5
